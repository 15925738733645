import { ENV } from 'gatsby-env-variables'
import isClient from 'utils/Browser/isClient'

function track(event, props) {
  try {
    if (isClient() && event) {
      if (ENV !== 'prod') {
        console.log('@analytics/ga - event', { event, props })
      }

      window.gtag('event', event, props)

      return
    }

    throw new Error('window.gtag or event is not defined')
  } catch (err) {
    console.error('@analytics/ga - error', err)
  }
}

export default { track }
