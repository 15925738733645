import { useEffect, useState } from 'react'
import { If } from '@redsales/ds'
import LandingPage from 'components/LandingPage'

import { Experiment, Variant } from 'react-optimize'
import GA from 'utils/GA'

import './Landing.scss'

const Landing = ({ location }) => {
  const [useABTest, setUseABTest] = useState(false)

  const LANDING_EXPERIMENT_ID = 'z7S9UeCCTy2Ww5fVm_vVwg'

  useEffect(() => {
    const { search } = location
    const params = new URLSearchParams(search)
    const abTest = params.get('ab_test')

    if (abTest === 'true') {
      setUseABTest(true)

      GA.track('page_view', {
        page_title: 'Landing Page - AB Testing',
      })
    } else {
      GA.track('page_view', {
        page_title: 'Landing Page A',
      })
    }
  }, [location])

  return (
    <If
      condition={useABTest}
      renderIf={
        <Experiment
          id={LANDING_EXPERIMENT_ID}
          loader={
            <div
              className="landing-loader"
              aria-live="polite"
              aria-busy="true"
              aria-label="Carregando..."
            />
          }
        >
          <Variant id="0">
            <LandingPage variant="A" />
          </Variant>
          <Variant id="1">
            <LandingPage variant="B" />
          </Variant>
        </Experiment>
      }
      renderElse={<LandingPage variant="A" />}
    />
  )
}

export default Landing
